// 打印单公共信息配置
export default {
  info: [
    [{
      label: '所属货主：',
      prop: 'shipperName',
      span: 6,
    }, {
      label: '供应商：',
      prop: 'supplierName',
      span: 6,
    }, {
      label: '司机/联系方式：',
      prop: '',
      span: 6,
    }, {
      label: '入库日期：',
      prop: 'finishUpperShelfTime',
      span: 6,
    }],
    [{
      label: '入库类别：',
      prop: 'receiptType',
      span: 6,
    }, {
      label: '客户订单号：',
      prop: 'customerOrderNo',
      span: 6,
    }, {
      label: '车辆车牌：',
      prop: 'plateNo',
      span: 6,
    }, {
      label: '入库单号：',
      prop: 'receiptOrderNo',
      span: 6,
    }],
    [{
      label: '备注：',
      prop: 'remark',
    }],
  ],
  tableHeaders: [
    {
      label: '货主货品编码', prop: 'shipperGoodsCode', class: 'letter-spacing-1', width: '9.5%',
    },
    { label: '货品名称', prop: 'goodsName' },
    { label: '规格', prop: 'spec' },
    { label: '温层', prop: 'temperatureLayer', width: '4%' },
    { label: '预约数量', prop: 'bookingNum', width: '8%' },
    { label: '预约重量', prop: 'bookingWeight', width: '8%' },
    {
      label: '实收数量',
      width: '8%',
      children: [
        { label: '整单位', prop: '' },
        { label: '零单位', prop: '' },
      ],
    },
    { label: '单板数量', prop: 'storageRuleStr', width: 80 },
    { label: '保质期', prop: 'shelfLife', width: '9%' },
    { label: '生产日期', prop: 'productDate', width: 80 },
    { label: '<div style="line-height: 15px;">允收期</br>(距生产日)</div>', prop: 'dueInDays', width: 80 },
    { label: '库存最新生产日期', prop: 'newestProductDate', width: 100 },
  ],
  sign: [
    [{
      label: '异常记录：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }, {
      label: '异常是否经客户授权：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }],
    [{
      label: '开始卸货时间：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }, {
      label: '结束卸货时间：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }],
    [{
      label: '制单人：',
      width: '15',
    }, {
      prop: 'createUserName',
      width: '35',
    }, {
      label: '场馆/道口：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }],
    [{
      label: '司机核实：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }, {
      label: '单证交接：',
      width: '15',
    }, {
      prop: '',
      width: '35',
    }],
  ],
};
