<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <PrintButton
        v-model="temperatureCode"
        :type="TEMPERATURE_LAYER_BUTTON.type"
        :temperature-list="TEMPERAYURE_LIST"
        @change="temperatureChanged"
      />
    </div>
    <div v-for="(printList, printNumber) in filterPrints" :key="printNumber">
      <component :is="printList.component" :print-list="printList" />
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';

import PrintButton from '../components/PrintButton/PrintButton.vue';
import Normal from './normal/index.vue';
import { TEMPERATURE_LAYER_BUTTON } from '../components/PrintButton/config';

import { TEMPERAYURE_LIST } from './fileds';
import config from './config';
import batchPrint from './api';

export default {
  components: {
    PrintButton,
    Normal,
  },
  mixins: [loadingMixin],
  data() {
    return {
      TEMPERATURE_LAYER_BUTTON,
      TEMPERAYURE_LIST,
      config,
      receiptOrderIds: [],
      prints: [],
      temperatureCode: '',
      filterPrints: [],
      loading: {
        init: false,
      },
    };
  },
  created() {
    const { ids } = this.$route.query;
    if (Array.isArray(ids)) {
      this.receiptOrderIds = ids;
    } else {
      this.receiptOrderIds = [ids];
    }
    this.init();
  },
  methods: {
    async init() {
      try {
        const resp = await batchPrint({ receiptOrderIds: this.receiptOrderIds });
        this.prints = resp.map((item) => ({
          ...item,
          component: config.DEFAULT, // 打印样式
        }));
        this.temperatureChanged();
      } catch (error) {
        this.prints = [];
      }
    },
    // 温层筛选
    temperatureChanged() {
      if (this.temperatureCode) {
        const filterPrints = this.prints.map((list) => ({
          ...list,
          goods: list.goods.filter((item) => item.temperatureLayer === this.temperatureCode),
        }));
        this.filterPrints = filterPrints.filter((list) => list.goods.length > 0);
      } else {
        this.filterPrints = [...this.prints];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  margin: 0 auto;
  width: 1123px;
}

.row {
  display: flex;

  .col {
    span {
      font-weight: 600;
    }
  }
}

.afterFooter {
  display: none;
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
