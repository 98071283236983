<template>
  <!-- 路径【入库管理】>>>【入库预约】（待验货）>>>打印 -->
  <div class="printWj">
    <print-header
      class="storage-print-header"
      :order="form.receiptOrderNo"
      :title="config.title"
      :barcode-config="{
        height: 70,
        width: 1.7
      }"
      :print-config="printList"
    />
    <print-info :info="fileds.info" :form="form" />
    <check-table />
    <print-table
      :headers="fileds.tableHeaders"
      :list="tableData"
      class="print-table"
    />
    <sign
      class="sign"
      :sign="fileds.sign"
      :data="form"
    />
  </div>
</template>
<script>
import unitNumber from '@/utils/unitNumber';
import Moment from '@/utils/moment';
import { YMD } from '@/constant/timeFormat';
import PrintHeader from '../../components/PrintHeader/PrintHeader.vue';
import PrintInfo from '../../components/PrintInfo/PrintInfo.vue';
import CheckTable from '../components/checkTable.vue';
import PrintTable from '../../components/PrintTable/PrintTable.vue';
import Sign from '../components/sign.vue';

import config from './config';
import fileds from './fileds';
import {
  RECEIPT_TYPE, TEMPERATURE_LAYER,
} from '../fileds';

export default {
  components: {
    PrintHeader,
    PrintInfo,
    CheckTable,
    PrintTable,
    Sign,
  },
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      config,
      fileds,
      form: {},
      checkList: [{}],
      tableData: [],
    };
  },
  watch: {
    printList: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { goods, ...form } = this.printList;
      this.form = {
        ...form,
        receiptType: form.receiptType && RECEIPT_TYPE[form.receiptType],
        finishUpperShelfTime: Moment.format(form.bookingTime, YMD),
      };
      this.featchDate(goods);
    },
    // 通过发运单号获取发运单详情，基本信息页面
    featchDate(goods) {
      this.tableData = goods.map((item) => ({
        ...item,
        bookingWeight: item.bookingWeight && `${item.bookingWeight}kg`,
        bookingNum: item.bookingNum && unitNumber.calculate({
          ...item,
          smallUnitNumber: item.bookingNum,
        }).txt,
        temperatureLayer: item.temperatureLayer && TEMPERATURE_LAYER[item.temperatureLayer],
        newestProductDate: Moment.format(item.newestProductDate, YMD),
        productDate: Moment.format(item.productDate, YMD),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.storage-print-header {
  margin-bottom: 30px;
}
</style>
<style lang="scss">
.printWj {
  page-break-after: always;

  .print-table {
    font-size: 14px;
    font-family: Microsoft YaHei, Tahoma, Verdana, STHeiTi, simsun, sans-serif;

    th {
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      white-space: nowrap;
    }

    tbody td {
      height: auto;
      padding: 10px 0;
    }
  }

  .sign {
    margin-top: 5px;
    border-top: 1px solid #000;
  }
}

@media print {
  .print-btn {
    display: none;
  }

  .checkManagePrint-table {
    padding-bottom: 0;
  }
}
</style>
