<template>
  <table
    class="check-label-component"
    cellspacing="0"
    cellpadding="0"
  >
    <thead>
      <tr>
        <th colspan="6">
          卸载前仓库员工车辆检查
        </th>
        <th colspan="6">
          卸载过程中产品的检查 20%的抽检要求，如有破损需写明破损产品名称和数量
        </th>
      </tr>
      <tr>
        <th>清洁卫生</th>
        <th>有温度跟踪仪器</th>
        <th>无异味</th>
        <th>无潜在污染源</th>
        <th>无虫蚀有害物及寄生虫</th>
        <th>备注</th>
        <th>箱体包装完整无损</th>
        <th>箱体标志完整</th>
        <th>数量正确</th>
        <th>包装完好</th>
        <th>未过保质期</th>
        <th>温度</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
</template>

 <style lang="scss" scoped>
.check-label-component {
  width: 100%;
  margin-bottom: 10px;
  font-family: Microsoft YaHei, Tahoma, Verdana, STHeiTi, simsun, sans-serif;
  font-size: 14px;

  th {
    font-weight: normal;
  }

  td {
    height: 30px;
  }

  td,
  th {
    padding: 8px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;

    &:last-child {
      border-right: 1px solid #000;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid #000;
    }
  }
}

 </style>
