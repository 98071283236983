// 入库类型

const RECEIPT_TYPE = {
  ORDER: '订单入库',
  RETURNED: '客退入库',
  REJECTED: '拒收入库',
  TRANSFER: '调拨入库',
  SETUP: '期初建账',
  OTHER: '其它入库',
};
// 入库状态
const RECEIPT_STATUS = {
  PRE_AUDIT: '待审核',
  PRE_CHECK_IN: '待登记',
  PRE_ACCEPT: '待验收',
  ACCEPTING: '验收中',
  PRE_PUT_ON_SHELVES: '待上架',
  DOING_PUT_ON_SHELVES: '上架中',
  DONE_PUT_ON_SHELVES: '上架完成',
  CANCELLED: '已取消',
};
// 温层
const TEMPERAYURE_LIST = [{
  temperatureLayerCode: '',
  temperatureLayerName: '全部',
}, {
  temperatureLayerCode: 'NORMAL',
  temperatureLayerName: '常温',
}, {
  temperatureLayerCode: 'COLD',
  temperatureLayerName: '冷冻',
}, {
  temperatureLayerCode: 'REFRIGERATE',
  temperatureLayerName: '冷藏',
}, {
  temperatureLayerCode: 'CONSTANT',
  temperatureLayerName: '恒温',
}];
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
const QUALITY = {
  GOOD: '良品',
  BAD: '不良品',
  SCRAP: '报废品',
};
export {
  RECEIPT_TYPE, RECEIPT_STATUS, TEMPERATURE_LAYER, QUALITY, TEMPERAYURE_LIST,
};
